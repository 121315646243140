.dx-g-bs4-cursor-pointer {
  cursor: pointer;
}

.dx-g-bs4-user-select-none {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: "none";
}

.dx-g-bs4-inactive:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  opacity: 0.7;
  pointer-events: none;
  z-index: 400;
}

.dx-g-bs4-overflow-hidden {
  overflow: hidden;
}

.dx-g-bs4-column-chooser-item {
  font-size: initial;
}

.dx-g-bs4-column-chooser-checkbox {
  margin-right: 10px;
}

.dx-g-bs4-sorting-indicator {
  top: 0;
  font-size: 11px;
}

span.dx-g-bs4-group-panel-item-icon {
  top: -2px;
  font-size: 11px;
  margin-left: -4px;
}

.dx-g-bs4-group-panel-empty-message {
  padding: 11px 0;
}

.dx-g-bs4-table-detail-toggle-cell-icon {
  font-size: 9px;
  top: 0;
}

.dx-g-bs4-table-group-row-cell {
  font-size: 9px;
  top: 0;
  margin-right: 10px;
}

.dx-g-bs4-table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  margin: 0;
}

.dx-g-bs4-table-sticky {
  position: -webkit-sticky;
  position: sticky;
  z-index: 500;
}

.dx-g-bs4-table-head {
  top: 0;
}

.dx-g-bs4-table-foot {
  bottom: 0;
}

.dx-g-bs4-table-container {
  flex-grow: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.dx-g-bs4-grouping-control {
  width: 19px;
}

.dx-g-bs4-grouping-control-icon {
  top: 0;
  font-size: 12px;
  padding: 5px;
}

.dx-g-bs4-table-header-cell-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  margin: -5px;
  padding: 5px;
  flex: 1;
}

.dx-g-bs4-table-header-cell-left {
  margin-right: 14px;
}

.dx-g-bs4-table-header-cell-right {
  margin-left: 14px;
}

.table .dx-g-bs4-table-edit-cell {
  padding: 5px;
}

button.dx-g-bs4-table-edit-command-cell {
  padding: 11px;
}

.dx-g-bs4-toolbar {
  align-items: center;
  min-height: 55px;
  flex: none;
}

.dx-g-bs4-paging-panel {
  flex: none;
}

.dx-g-bs4-toggle-button {
  cursor: pointer;
  display: inline-block;
  font-size: 9px;
  top: 0;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  box-sizing: content-box;
  min-width: 9px;
}

.dx-g-bs4-toggle-button-hidden {
  opacity: 0;
  cursor: default;
}

.dx-g-bs4-sorting-control-text,
.dx-g-bs4-table-tree-content,
.dx-g-bs4-table-cell,
.dx-rg-bs4-table-header-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dx-g-bs4-resizing-control-wrapper {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: "none";
  position: absolute;
  width: 16px;
  top: 0;
  right: -8px;
  height: 100%;
  cursor: col-resize;
  z-index: 100;
  opacity: 0;
}

.dx-g-bs4-header-cell:nth-last-child(2) .dx-g-bs4-resizing-control-wrapper {
  right: 0;
  width: 8px;
}
.dx-g-bs4-header-cell:hover .dx-g-bs4-resizing-control-wrapper {
  opacity: 1;
}

.dx-g-bs4-resizing-control-wrapper-active {
  opacity: 1;
}

.dx-g-bs4-resize-control-line {
  opacity: 1;
  position: absolute;
  height: 50%;
  width: 1px;
  top: 25%;
  transition: all linear 100ms;
}

.dx-g-bs4-resize-control-line-first {
  left: 7px;
}

.dx-g-bs4-resize-control-line-second {
  left: 9px;
}

.dx-g-bs4-resize-control-line-active {
  left: 8px;
  height: calc(100% - 4px);
  top: 2px;
}

.dx-g-bs4-banded-header-cell:last-child {
  border-right: 0 !important;
}

.dx-g-bs4-banded-cell {
  border-bottom: none !important;
}
.dx-g-bs4-banded-cell:last-child {
  border-right: none !important;
}

.dx-g-bs4-filter-selector-item {
  font-size: initial;
}
.dx-g-bs4-filter-selector-item:focus {
  outline: none;
}

.dx-g-bs4-filter-selector-item-text {
  margin-left: 10px;
}

.dx-g-bs4-filter-selector-icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
}

.table-active, .table-active > td, .table-active > th {
  background-color: #f5f5f5;
}

.dx-g-bs4-fixed-cell,
.dx-g-bs4-fixed-group-cell {
  z-index: 300;
  background-clip: padding-box;
}

.dx-g-bs4-no-data-cell {
  position: static !important;
}

.dx-g-bs4-fixed-group-cell {
  display: inline-block;
}

.dx-g-bs4-table-summary-item {
  font-weight: bold;
}

.dx-g-bs4-table-invisible-row {
  visibility: hidden;
}

.dx-g-bs4-header-cell-content {
  min-width: 0;
}

.dx-g-bs4-sort-indicator-invisible {
  opacity: 0;
}

.dx-g-bs4-fixed-block {
  position: -webkit-sticky;
  position: sticky;
  display: inline-block;
  left: 50%;
}
.dx-g-bs4-fixed-block .text-muted {
  display: inline-block;
  transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}

.dx-g-bs4-skeleton-cell {
  padding: 7px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAACqCAYAAABbAOqQAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA39pVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpjYWQ2ODE5MS00ZDMxLWRjNGYtOTU0NC1jNjJkMTIxMjY2M2IiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MjY1RUVFQzAzRDYzMTFFODlFNThCOUJBQjU4Q0EzRDgiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MjY1RUVFQkYzRDYzMTFFODlFNThCOUJBQjU4Q0EzRDgiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjVlMjM1Y2U0LTc5ZWUtNGI0NC05ZjlkLTk2NTZmZGFjNjhhNCIgc3RSZWY6ZG9jdW1lbnRJRD0iYWRvYmU6ZG9jaWQ6cGhvdG9zaG9wOjk1OTQ2MjBiLTUyMTQtYTM0Yy04Nzc5LTEwMmEyMTY4MTlhOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvLbJKYAAADrSURBVHja7N3BDYBACABBsQn7L48q0BoMD5SZxAZuc74gF1V1MMfpCARBEEEQRBAEEQRBdovnuxxDq3RD/LIQRBAEQRBBEEQQBBEEQQQBAAAAAAAAABhi8gZVbgxi6kQQBBEEQQRBEEEQRBAEQRBBAAAAAAAAAAAabX2Daux2lqkTQRBEEAQRBEEEQRBBEARBBAEAAAAAAAAAaLR1g2osUyeCIIggCCIIggiCIIIgCIIIAgAAAAAAAADQ6KsbVPnXIKZOBEEQQRBEEAQRBEEEQRAEEYRXoqqcghuCIIIgiCAIIgiCCMIUtwADALYCCr92l++TAAAAAElFTkSuQmCC);
  background-repeat: no-repeat repeat;
  background-origin: content-box;
}